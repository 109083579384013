import React from "react";
import { RadioStyle, RadioOptionStyle, LabelStyle } from "./style";
import ErrorMessage from "../../ErrorMessage";

const Radio = props => {
  const { fieldType, fieldLabel, fieldName, listItems, isRequired } = props.data;

  const renderRadioOptions = () => {
    return listItems.map(item => {
      return (
        <span>
          <input
            id={`${fieldType}_${fieldName}_${item.key}`}
            type="radio"
            name={fieldName}
            ref={props.reference}
            value={item.key}
          />
          <label htmlFor={`${fieldType}_${fieldName}_${item.key}`}>
            {item.value}
          </label>
        </span>
      );
    });
  };

  return (
    <RadioStyle>
      <LabelStyle>{fieldLabel && fieldLabel} {isRequired ? '*' : ''}</LabelStyle>
      <RadioOptionStyle>{renderRadioOptions()}</RadioOptionStyle>
      {props.errors && props.errors[fieldName] && (
        <ErrorMessage lang={props.lang} />
      )}
    </RadioStyle>
  );
};

export default Radio;
