import styled from "styled-components";
import { desktopMedia } from "../../../../styles/themes/constants";

export const HeroBannerContainer = styled.div`
  img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    ${desktopMedia} {
      height: 500px;
    }
  }
`;
