import React from "react";
import { HeroBannerContainer } from "./style";
import banner from "../../../../assets/images/banner.jpeg";

const HeroBanner = () => {
  return (
    <HeroBannerContainer>
      <img src={banner} alt="banner" />
    </HeroBannerContainer>
  );
};

export default HeroBanner;
