import React from "react";
import { TitleStyled } from "./style";

const Title = props => {
  return (
    <TitleStyled>
      <h1>{props.title}</h1>
    </TitleStyled>
  );
};

Title.propTypes = {};

export default Title;
