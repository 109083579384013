export const breakpoints = {
  keys: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"],
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
    xxxl: 2048,
  },
};

export const desktopMedia = `@media (min-width: ${breakpoints.values.lg}px)`;
export const mobileMedia = `@media (max-width: ${breakpoints.values.lg - 1}px)`;

export const arabicLang = "ar";
