/* Colors */

const darkBlue = "#476A6F";
const lightBlue = "#258EA6";
const darkGreen = "#519E8A";
const mediumGreen = "#7EB09B";
const lightGreen = "#A3C4BC";
const yellow = "#efbd47";
const borderColor = "rgb(118, 118, 118)";
const red = "rgb(194, 0, 47)";
const disabledRed = "rgb(194,0,47, 0.6)"
const blueThemeGradient = "linear-gradient(90deg, #36d7b7, #2b303b)";
const lightYellow = '#fdf8ec';
const greyBlack = '#4c4d4f';
const darkGrey = '#555658';

export const palette = {
    darkBlue,
    lightBlue,
    darkGreen,
    mediumGreen,
    lightGreen,
    yellow,
    borderColor,
    red,
    blueThemeGradient,
    lightYellow,
    greyBlack,
    darkGrey,
    disabledRed,
};