import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const TitleStyled = styled.div`
  line-height: 5rem;
  ${props => props.theme.mixins.marginLeft(pxToRem(16))};
  padding: ${pxToRem(0)} ${pxToRem(24)};
  ${desktopMedia} {
    padding: ${pxToRem(0)} ${pxToRem(88)};
    h1 {
      font-size: ${pxToRem(40)};
    }
  }
`;
