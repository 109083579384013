import { getLanguageValues } from "../../../Utils/GoldenMultiLingualValues";
import RichText from "../../atoms/RichText";

export const HomePageData = (lang) => ({
  proceedButtonData: {
    isFormGroup: false,
    fieldType: "submit",
    fieldLabel: getLanguageValues(lang, "proceedText"),
  },
  homePageText: {
    isFormGroup: false,
    fieldType: "readonly",
    content: getLanguageValues(lang, "instructionContentText"),
    richText: true,
  },
  privacyCheckBox: {
    fieldType: "checkbox",
    fieldLabel: (
      <RichText
        content={getLanguageValues(lang, "privacyPolicyCheckboxLinkText")}
      />
    ),
    isRequired: false,
    listItems: [],
    fieldName: "privacyPolicyCheckboxLinkText",
  },
});
