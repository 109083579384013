import styled from "styled-components";

export const DatePickerStyle = styled.fieldset`
  .date-picker {
    width: calc(100% - 22px);
    padding: 0.625rem;
    border: 1px solid rgb(0,0,0,0.3);
  }
  .date-picker.error {
    border: 1px solid;
    border-color: ${props => props.theme.palette.red};
  }
`;
