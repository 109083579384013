import React, { useEffect, useState } from "react";

import { CreativeVisaPageContainer } from "./style";
import CreativeVisaForm from "../../molecules/CreativeVisaForm";
import axios from "axios";
import { getAllDropdownValues } from "../../../Utils/services";
// import { dropdownData } from "./data";
import Loading from "react-fullscreen-loading";

const CreativeVisaPage = (props) => {
  const [dropdownValues, setdropdownValues] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    axios
      .get(getAllDropdownValues, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "bb43772e423a4ad59e93513598bef5ea",
        },
      })
      .then((data) => {
        setdropdownValues(data.data);
        setShowLoader(false);
      });
  }, []);

  return (
    <CreativeVisaPageContainer>
      <Loading
        loading={showLoader}
        background="rgb(0,0,0, 0.8)"
        loaderColor="#fff"
      />
      <div>
        {/* {Object.keys(dropdownData).length && (
          <CreativeVisaForm lang={props.lang} dropdownData={dropdownData} />
        )} */}
        {Object.keys(dropdownValues).length && (
          <CreativeVisaForm lang={props.lang} dropdownData={dropdownValues} />
        )}
      </div>
    </CreativeVisaPageContainer>
  );
};

export default CreativeVisaPage;
