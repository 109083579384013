import { UPDATE_LANGUAGE } from "../actionTypes/actionTypes";

const intialState = {
  lang: "en",
};

export default function languageReducer(state = intialState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}
