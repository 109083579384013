import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

export const CheckboxStyle = styled.fieldset`
  width: 100% !important;
`;

export const CheckboxOptionStyle = styled.div`
  display: flex;
  label {
    margin: 0;
    ${props => props.theme.mixins.marginLeft(pxToRem(8))};
  }
`;
