/* Const defination */
export const BASE_FONT_SIZE = 16;

/* Function */
export const pxToRem = pixelValue => `${pixelValue / BASE_FONT_SIZE}rem`;

/*
    This method checks for window object and process.browser
    Accept params: na
    Return: {boolean}
*/

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement &&
  process.browser
);

const checkForDataLayerObj = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

export const pushAnaylyticsData = analyticsInfo => {
  if (canUseDOM) {
    const winObj = checkForDataLayerObj();
    if (winObj) {
      winObj.push(analyticsInfo);
    }
  }
};