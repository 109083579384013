import styled from "styled-components";

const SelectStyle = styled.fieldset`
  .select-box > div:first-of-type {
    border-color: ${props => props.theme.palette.borderColor};
    border: 1px solid rgb(0,0,0,0.3);
  }
  .select-box input {
    width: 0 !important;
  }
  .select-box.error > div:first-of-type {
    border-color: ${props => props.theme.palette.red};
  }
`;

export default SelectStyle;
