/*
  This will hold all the theming info for creative visa app
  add updates to theme will reflect across website.
*/
import { palette } from "./colors";
import { breakpoints, arabicLang } from "./constants";
import { mixins } from "./mixins";
import shadows from "./shadows";
import { borders } from "./borders";
import { getFontFamily } from "./utils";

const theme = selectedLang => {
  const isRtl = selectedLang === arabicLang;
  const fontFamily = getFontFamily(selectedLang);

  return {
    palette: {
      ...palette,
    },
    typography: {
      fontFamily: fontFamily,
    },
    breakpoints: breakpoints,
    mixins: {
      ...mixins(isRtl),
    },
    borders,
    shadows,
    direction: isRtl ? "rtl" : "ltr",
    isDirectionRTL: isRtl,
  };
};

export default theme;
