const formFieldTypes = {
  SINGLELINETEXT: "textbox",
  EMAIL: "email",
  PHONE: "number",
  INPUTFILE: "file",
  DROPDOWN: "dropdown",
  DATE: "date",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  CAPTCHA: "captcha",
  SUBMIT: "submit",
  READONLY: "readonly",
};

export default formFieldTypes;
