import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

const InputStyle = styled.input`
  padding: ${pxToRem(10)};
  border: 1px solid rgb(0,0,0,0.3);
  /* ${props => props.theme.mixins.marginLeft(40)} */
  &.error {
    border: 1px solid;
    border-color: ${props => props.theme.palette.red};
  }
`;

export default InputStyle;
