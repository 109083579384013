import React from "react";
import PropTypes from "prop-types";
import InputStyle from "./style";

const InputField = props => {
  const { fieldType, fieldLabel, fieldName, isRequired } = props.data;

  const getFieldType = type => {
    switch (type) {
      case "textbox":
        return "text";
      case "email":
        return "email";
      case "number":
        return "number";
      default:
        return "text";
    }
  };
  return (
    <fieldset>
      <label
        htmlFor={`${fieldType}_${fieldName}`}
        className={props.errors && props.errors[fieldName] && "error"}
      >
        {fieldLabel && fieldLabel}{isRequired ? '*' : ''}
      </label>
      <InputStyle
        id={`${fieldType}_${fieldName}`}
        name={fieldName}
        type={getFieldType(fieldType)}
        ref={props.reference}
        // placeholder={fieldLabel && fieldLabel}
        className={props.errors && props.errors[fieldName] && "error"}
      />
      {/* {props.errors && props.errors[fieldName] && (
        <ErrorMessage lang={props.lang} />
      )} */}
    </fieldset>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
};

export default InputField;
