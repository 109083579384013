import React, { useState } from "react";
import ErrorMessage from "../../ErrorMessage";
import {
  UploadedDOCStyle,
  UploadIconStyle,
  LabelStyle,
  RemoveFileStyle,
} from "./style";

const InputFile = (props) => {
  const {
    fieldType,
    fieldLabel,
    fieldName,
    isMultipleUpload,
    maxUpload,
    isRequired,
  } = props.data;
  const [uploadedFiles, setUploadedFiles] = useState("");

  const handleChagne = (e) => {
    debugger;
    console.info("File Change",fieldType,fieldLabel,fieldName)
    if (e.target && e.target.type === "file") {
      try {
        const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "doc", "docx"];
        const allowedExtensionsDoc=["pdf", "doc", "docx"];

        
        // destructuring file name and size from file object
        const { name: fileName, size: fileSize } = e.target.files[0];

        /*
         * if the filename is apple.png, we split the string to get ["apple","png"]
         * then apply the pop() method to return the file extension (png)
         *
         */
        const fileExtension = fileName.split(/[. ]+/).pop();

        /* 
    check if the extension of the uploaded file is included 
    in our array of allowed file extensions
  */
    if(fieldName=="attachment_EvidenceWorkAUH")
    {
      if (!allowedExtensionsDoc.includes(fileExtension.toLowerCase())) {
        alert("please upload only pdf and doc files");
        e.target.value = null;
        return;
      }
    }
    else
    {
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        alert("please upload only pdf, doc and images files");
        e.target.value = null;
        return;
      }
    }
        

        if (e.target.files.length) {
          setUploadedFiles(e.target.files[0]);
        }
      } catch (err) {}
    }
  };

  const onFileDelete = (key, name) => {
    setUploadedFiles({});
    // props.reference.value = "";
    // fileInput.value = "";
    let element = document.querySelector(`#${fieldType}_${fieldName}`);
    element.value = "";
  };
  if (isMultipleUpload) {
    return (
      <fieldset>
        <LabelStyle
          htmlFor={`${fieldType}_${fieldName}`}
          className={props.errors && props.errors[fieldName] && "error"}
          disabled={
            props.uploadedFiles &&
            props.uploadedFiles[fieldName] &&
            props.uploadedFiles[fieldName].length >= maxUpload
          }
        >
          <UploadIconStyle
            className={
              props.uploadedFiles &&
              props.uploadedFiles[fieldName] &&
              props.uploadedFiles[fieldName].length >= maxUpload &&
              "disabled"
            }
          />
          {fieldLabel && fieldLabel}
          {isRequired ? "*" : ""}
        </LabelStyle>
        <input
          id={`${fieldType}_${fieldName}`}
          name={fieldName}
          type={fieldType}
          onChange={props.onChange}
          style={{ display: "none" }}
          disabled={
            props.uploadedFiles &&
            props.uploadedFiles[fieldName] &&
            props.uploadedFiles[fieldName].length >= maxUpload
          }
          ref={props.reference}
        />
        {props.errors && props.errors[fieldName] && (
          <ErrorMessage lang={props.lang} />
        )}
        {props.uploadedFiles &&
          props.uploadedFiles[fieldName] &&
          props.uploadedFiles[fieldName].length &&
          props.uploadedFiles[fieldName].map((file) => {
            return (
              <UploadedDOCStyle>
                {file.name}
                <RemoveFileStyle
                  onClick={() =>
                    props.onFileDelete(file.lastModified, fieldName, fieldType)
                  }
                >
                {props.lang=="ar"?"حذف":"Remove"}
                </RemoveFileStyle>
              </UploadedDOCStyle>
            );
          })}
      </fieldset>
    );
  } else {
    let removeStyleBtn = "";
    if (uploadedFiles.lastModified) {
      removeStyleBtn = (
        <RemoveFileStyle
          onClick={() => onFileDelete(uploadedFiles.lastModified, fieldName)}
        >
                   {props.lang=="ar"?"حذف":"Remove"}
        </RemoveFileStyle>
      );
    }
    return (
      <fieldset>
        <LabelStyle
          htmlFor={`${fieldType}_${fieldName}`}
          className={props.errors && props.errors[fieldName] && "error"}
        >
          <UploadIconStyle />
          {fieldLabel && fieldLabel}
          {isRequired ? "*" : ""}
        </LabelStyle>
        <input
          id={`${fieldType}_${fieldName}`}
          name={fieldName}
          type={fieldType}
          ref={props.reference}
          onChange={handleChagne}
          style={{ display: "none" }}
        />
        {props.errors && props.errors[fieldName] && (
          <ErrorMessage lang={props.lang} />
        )}
        {uploadedFiles && (
          <UploadedDOCStyle>
            {uploadedFiles.name}

            {removeStyleBtn}
          </UploadedDOCStyle>
        )}
      </fieldset>
    );
  }
};

export default InputFile;
