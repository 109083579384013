import React from "react";
import { ButtonContainerStyle, ButtonStyle } from "./style";

const Button = props => {
  const { fieldType, fieldLabel } = props.data;

  return (
    <ButtonContainerStyle>
      <ButtonStyle type={fieldType} onClick={props && props.onClick} disabled={props.disabled}>{fieldLabel}</ButtonStyle>
    </ButtonContainerStyle>
  );
};

export default Button;
