import React from "react";
import { ReadOnlyStyle } from "./style";
import RichText from "../RichText";

const ReadOnlyField = props => {
  const { contentHeading, content, richText } = props.data;
  return (
    <ReadOnlyStyle>
      <h3>{contentHeading}</h3>
      <p>{richText ? <RichText content={content} /> : content}</p>
    </ReadOnlyStyle>
  );
};

export default ReadOnlyField;
