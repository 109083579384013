const baseFont = " Arial, sans-serif";

export const fontFamilyEN = {
  primaryRegular: ["Lato-Regular", baseFont].join(","),
  primaryBold: ["Lato-Bold", baseFont].join(","),
};

export const fontFamilyAR = {
  primaryRegular: ["NotoKufiArabic", baseFont].join(","),
  primaryBold: ["NotoKufiArabic-Bold", baseFont].join(","),
};
