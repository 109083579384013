import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "react-fullscreen-loading";

import InputFile from "../../atoms/Forms/InputFile";
import InputField from "../../atoms/Forms/TextBox";
import SelectBox from "../../atoms/Forms/SelectBox";
import Radio from "../../atoms/Forms/Radio";
import CheckBox from "../../atoms/Forms/CheckBox";
import DatePicker from "../../atoms/Forms/Date";
import Button from "../../atoms/Forms/Button";
import { formData } from "./formData";
import formFieldTypes from "./constants";
import ReadOnlyField from "../../atoms/ReadOnlyField";
import {
  CreativeVisaFormContainer,
  CreativeVisaFormStyle,
  ErrorMessageAPIStyle,
  FieldStyle,
  SuccessMessageStyle,
} from "./style";
import axios from "axios";
import { postApplicantDetails } from "../../../Utils/services";
import ErrorMessage from "../../atoms/ErrorMessage";
import { getLanguageValues } from "../../../Utils/GoldenMultiLingualValues";
import { pushAnaylyticsData } from "../../../Utils/helperFunctions";

const CreativeVisaForm = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [recaptchachecked, setRecaptchachecked] = React.useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = React.useState(false);
  const [showRecaptchaError, setShowRecaptchaError] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [dateError, setdateError] = useState(false);
  const [xssError, setxssError] = useState(false);

  const { register, handleSubmit, watch, control, errors, setValue } =
    useForm();
  const { dropdownData, lang } = props;
  const {
    SINGLELINETEXT,
    EMAIL,
    PHONE,
    INPUTFILE,
    DROPDOWN,
    DATE,
    CHECKBOX,
    RADIO,
    CAPTCHA,
    SUBMIT,
    READONLY,
  } = formFieldTypes;

  const applicantTypeValue = watch("applicantTypeId");
  const uaeVisaValue = watch("hasUaeVisa");
  const legalIssueValue = watch("hasLegalIssue");
  const qualificationValue = watch("qualificationId");
  const religionVlaue = watch("religionId");
  const visaCategoryValue = watch("visaCategoryId");
  const religionValue = watch("religionId");
  const dependentSpoonsorValue = watch("havedependantsSponsor");
  const existingVisaTypeValue = watch("ExistingVisaTypeId");

  useEffect(() => {
    window.scrollTo(0, 0);
    setValue("sign_date_form", new Date());
  }, []);

  const validateDate = (dateString) => {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  const ValidatexssExists = (obj) => {
    setxssError(false);
    let IsxssExists = false;
    Object.keys(obj).forEach(function (key, index) {
      // key: the name of the object key
      // index: the ordinal position of the key within the object
      if (
        obj[key] &&
        obj[key].toString() &&
        obj[key].toString().includes("<")
      ) {
        setxssError(true);
        IsxssExists = true;
      }
    });
    return IsxssExists;
  };
  const onSubmit = (data) => {
    if (recaptchachecked) {
      const locale = "en-US";
      if (
        validateDate(data.dateOfBirth.toLocaleDateString(locale)) &&
        validateDate(data.passportExpiryDate.toLocaleDateString(locale)) &&
        validateDate(data.passportIssueDate.toLocaleDateString(locale)) &&
        validateDate(data.sign_date_form.toLocaleDateString(locale)) &&
        (!data.existingVisaExpiryDate ||
          validateDate(data.existingVisaExpiryDate.toLocaleDateString(locale)))
      ) {
        setdateError(false);
      } else {
        setdateError(true);
        return;
      }

      if (ValidatexssExists(data)) {
        return;
      }
      pushAnaylyticsData("clicked", "Submit Golden Visa");
      setShowLoader(true);
      setShowRecaptchaError(false);

      const VisaTypes = getDropdownValues("visaTypes");
      const CurrentVisaType = VisaTypes.filter((rows) => {
        return rows.label === getLanguageValues(lang, "golden"); //getLanguageValues(lang, "golden")
      });
      data.visaTypeId = CurrentVisaType[0].value;
      console.log({ ...data, ...uploadedFiles });
      const requestObject = { ...data, ...uploadedFiles };
      let fileFormData = new FormData();
      let applicantFormData = {};
      for (const key in requestObject) {
        if (
          key.includes("attachment") &&
          requestObject[key] &&
          requestObject[key].length === 1 &&
          requestObject[key][0] &&
          requestObject[key][0].name
        ) {
          fileFormData.append(key, requestObject[key][0]);
          delete requestObject[key];
        } else if (
          key.includes("attachment") &&
          requestObject[key] &&
          requestObject[key].length > 1 &&
          requestObject[key][0] &&
          requestObject[key][0].name
        ) {
          for (let index = 0; index < requestObject[key].length; index++) {
            const element = requestObject[key][index];
            fileFormData.append(index + key, element);
          }
          delete requestObject[key];
        }
      }
      applicantFormData = { ...applicantFormData, ...requestObject };
      let hoursDiff =
        applicantFormData.dateOfBirth.getHours() -
        applicantFormData.dateOfBirth.getTimezoneOffset() / 60;

      applicantFormData.dateOfBirth.setHours(hoursDiff);
      applicantFormData.passportExpiryDate.setHours(hoursDiff);
      applicantFormData.passportIssueDate.setHours(hoursDiff);
      if (applicantFormData.existingVisaExpiryDate)
        applicantFormData.existingVisaExpiryDate.setHours(hoursDiff);
      // applicantFormData.sign_date_form.setHours(hoursDiff);
      fileFormData.set("applicant", JSON.stringify(applicantFormData));

      axios
        .post(postApplicantDetails, fileFormData, {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": "bb43772e423a4ad59e93513598bef5ea",
          },
        })
        .then((data) => {
          setShowLoader(false);
          setApiError(false);
          if (data.status === 200 && data.data.referenceCode) {
            setFormSubmitStatus(data.data.referenceCode);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          setApiError(true);
        });
    } else {
      setShowRecaptchaError(true);
    }
  };

  const getDropdownValues = (keyName) => {
    let listItems;
    if (keyName === "visaActivities") {
      if (visaCategoryValue) {
        const valuesExtracted = dropdownData[keyName].filter((data) => {
          return data.visaCategoryId === visaCategoryValue;
        });
        listItems = valuesExtracted.map((value) => {
          return {
            label: lang === "ar" ? value.nameAr : value.name,
            value: value.id,
          };
        });
      } else {
        return [];
      }
    } else if (keyName === "faiths") {
      if (religionValue) {
        const valuesExtracted = dropdownData[keyName].filter((data) => {
          return data.religionId === religionValue;
        });
        if (valuesExtracted && valuesExtracted.length) {
          listItems = valuesExtracted.map((value) => {
            return {
              label: lang === "ar" ? value.nameAr : value.name,
              value: value.id,
            };
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      listItems = dropdownData[keyName].map((value) => {
        return {
          label: lang === "ar" ? value.nameAr : value.name,
          value: value.id,
        };
      });
    }

    return listItems;
  };

  const handleFileChange = (e) => {
    if (e.target && e.target.type === "file") {
      try {
        const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "doc", "docx"];
        const { name: fileName, size: fileSize } = e.target.files[0];
        const fileExtension = fileName.split(".").pop();
        if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
          alert("please upload only pdf, doc and images files");
          e.target.value = null;
          return;
        }

        if (e.target.files.length) {
          const files = e.target.files[0];
          const targetName = e.target.name;
          if (uploadedFiles[targetName]) {
            setUploadedFiles({
              ...uploadedFiles,
              [targetName]: [...uploadedFiles[targetName], files],
            });
          } else {
            setUploadedFiles({
              ...uploadedFiles,
              [targetName]: [files],
            });
          }
        }
      } catch (err) {}
    }
  };

  const onFileDelete = (key, name, type) => {
    const updatedFiles = uploadedFiles[name].filter(
      (file) => file.lastModified !== key
    );
    setUploadedFiles({
      ...uploadedFiles,
      [name]: updatedFiles,
    });
    let element = document.querySelector(`#${type}_${name}`);
    element.value = "";
  };

  const renderFormFields = (fields) => {
    // condition to view UAE visa sponsor field
    if (
      (fields.uaeVisaCondition && !uaeVisaValue) ||
      (fields.uaeVisaCondition &&
        uaeVisaValue &&
        uaeVisaValue !== fields.uaeVisaCondition)
    ) {
      return "";
    }

    // condition to view UAE Health Insurance field
    // if (
    //   (fields.HealthInsuranceCondition && !existingVisaTypeValue) ||
    //   (fields.HealthInsuranceCondition &&
    //     existingVisaTypeValue &&
    //     existingVisaTypeValue.toString() !== fields.HealthInsuranceCondition)
    // ) {
    //   return "";
    // }

    // condition to view legal issue details field
    if (
      (fields.legalIssueCondition && !legalIssueValue) ||
      (fields.legalIssueCondition &&
        legalIssueValue &&
        legalIssueValue !== fields.legalIssueCondition)
    ) {
      return "";
    }

    // checking for faith field value to change required behaviour of other related fields
    if (fields.religionCondition && !religionVlaue) {
      return "";
    }

    //checking for visa category value to render visa activity field
    if (fields.visaCategoryCondition && !visaCategoryValue) {
      return "";
    }

    switch (fields.fieldType) {
      case SINGLELINETEXT:
      case EMAIL:
      case PHONE:
        return (
          <InputField
            data={fields}
            reference={register({ required: fields.isRequired })}
            errors={errors}
            lang={props.lang}
          />
        );
      case INPUTFILE:
        if (fields.isMultipleUpload) {
          return (
            <InputFile
              reference={register({ required: fields.isRequired })}
              data={fields}
              errors={errors}
              lang={props.lang}
              onChange={handleFileChange}
              onFileDelete={onFileDelete}
              uploadedFiles={uploadedFiles}
            />
          );
        } else {
          return (
            <InputFile
              reference={register({ required: fields.isRequired })}
              data={fields}
              errors={errors}
              lang={props.lang}
            />
          );
        }
      case DATE:
        return (
          <DatePicker
            reference={register({ required: fields.isRequired })}
            date={new Date()}
            value={new Date()}
            formControl={control}
            data={fields}
            errors={errors}
            lang={props.lang}
          />
        );
      case DROPDOWN:
        return (
          <SelectBox
            reference={register({ required: fields.isRequired })}
            formControl={control}
            isLinkedToForm={true}
            data={fields}
            errors={errors}
            lang={props.lang}
          />
        );
      case CHECKBOX:
        return (
          <CheckBox
            reference={register({ required: fields.isRequired })}
            data={fields}
            errors={errors}
            lang={props.lang}
          />
        );
      case RADIO:
        return (
          <Radio
            reference={register({ required: fields.isRequired })}
            data={fields}
            errors={errors}
            lang={props.lang}
          />
        );
      case READONLY:
        return <ReadOnlyField data={fields} />;
      case CAPTCHA:
        return (
          <>
            <ReCAPTCHA
              sitekey={fields.captchaKey}
              onChange={recaptchaCallback}
            />
            {showRecaptchaError && <ErrorMessage />}
          </>
        );
      case SUBMIT:
        return <Button data={fields} />;
      default:
        return "not defined";
    }
  };

  const renderSuccessScreen = () => {
    return (
      <SuccessMessageStyle>
        <h2>
          {getLanguageValues(lang, "successMessage")}
          {formSubmitStatus}
        </h2>
      </SuccessMessageStyle>
    );
  };

  const recaptchaCallback = (value) => {
    //If value is long string then we have sucessfully submited
    // google captcha and now we can submit the form
    setRecaptchachecked(value.length > 0);
    setShowRecaptchaError(!(value.length > 0));
  };

  const renderForm = () => {
    const formFields = formData(props.lang);
    return formFields.map((field) => {
      if (field.isFormGroup) {
        // condition to view applicant fields
        if (
          (field.applicantCondition && !applicantTypeValue) ||
          (field.applicantCondition &&
            applicantTypeValue &&
            applicantTypeValue !== field.applicantCondition)
        ) {
          return "";
        }
        const formGroupDOM = (
          <div>
            <h3>{field.groupTitle}</h3>
            <div
              style={{
                fontSize: "small",
                color: "red",
              }}
              dangerouslySetInnerHTML={{ __html: field.groupDisclaimer }}
            ></div>
            <FieldStyle>
              {field.fields.map((fieldData) => {
                let newFieldData = fieldData;
                // adding dropdown values fetched from API
                if (
                  newFieldData.fieldType === DROPDOWN &&
                  newFieldData.keyName
                ) {
                  newFieldData = {
                    ...newFieldData,
                    listItems: getDropdownValues(newFieldData.keyName),
                  };
                }
                //checking for qualification field value to change required behaviour of otherr related fields
                if (
                  newFieldData.parentField == "qualificationId" &&
                  qualificationValue &&
                  newFieldData.parentValues &&
                  newFieldData.parentValues.length &&
                  newFieldData.parentValues
                    .split(",")
                    .includes(`${qualificationValue}`)
                ) {
                  newFieldData = {
                    ...newFieldData,
                    isRequired: true,
                  };
                }
                //checking for Existing Visa Type field value to change required behaviour of otherr related fields
                // if (
                //   newFieldData.HealthInsuranceCondition &&
                //   existingVisaTypeValue &&
                //   newFieldData.HealthInsuranceCondition ===
                //     existingVisaTypeValue.toString()
                // ) {
                //   newFieldData = {
                //     ...newFieldData,
                //     isRequired: false,
                //   };
                // }
                //Dependant passport validation
                if (
                  newFieldData.fieldName === "attachment_Dependant_passport" &&
                  dependentSpoonsorValue &&
                  dependentSpoonsorValue === "true"
                ) {
                  newFieldData = {
                    ...newFieldData,
                    isRequired: true,
                  };
                }

                return renderFormFields(newFieldData);
              })}
            </FieldStyle>
          </div>
        );
        return formGroupDOM;
      } else {
        return <>{renderFormFields(field)}</>;
      }
    });
  };

  return (
    <CreativeVisaFormContainer>
      <Loading
        loading={showLoader}
        background="rgb(0,0,0, 0.8)"
        loaderColor="#fff"
      />
      <CreativeVisaFormStyle onSubmit={handleSubmit(onSubmit)}>
        {!formSubmitStatus ? renderForm() : renderSuccessScreen()}
        {dateError && (
          <ErrorMessageAPIStyle>
            {" "}
            <ErrorMessage
              message={getLanguageValues(lang, "dateErrorMessage")}
            />
          </ErrorMessageAPIStyle>
        )}
        {apiError && (
          <ErrorMessageAPIStyle>
            {" "}
            <ErrorMessage
              message={getLanguageValues(lang, "apiErrorMessage")}
            />
          </ErrorMessageAPIStyle>
        )}
        {xssError && (
          <ErrorMessageAPIStyle>
            {" "}
            <ErrorMessage
              message={getLanguageValues(lang, "xssErrorMessage")}
            />
          </ErrorMessageAPIStyle>
        )}
      </CreativeVisaFormStyle>
    </CreativeVisaFormContainer>
  );
};

export default CreativeVisaForm;
