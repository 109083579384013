import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: ${props => props.theme.shadows[6]};
  padding: ${pxToRem(24)} ${pxToRem(24)};
  ${desktopMedia} {
    padding: ${pxToRem(24)} ${pxToRem(88)};
  }
`;

export const LanguageSelectorContainer = styled.div`
  width: ${pxToRem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  img {
    width: 120px;
    ${desktopMedia} {
      width: 175px;
    }
  }
`;
