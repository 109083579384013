import React from "react";

import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import HomePage from "./global/components/organism/HomePage";
import GoldenHomePage from "./global/components/organism/GoldenHomePage";

const App = (props) => {
  return (
    <BrowserRouter>
      <Route
        path="/"
        render={(routeProps) => {
          const [subdomain] = window.location.hostname.split(".");
          console.log("subdomain: ", subdomain);
          if (subdomain === "endorsementform")
          //   //"endorsementform")
            return <GoldenHomePage lang={props.lang} />;
          return <HomePage lang={props.lang} />;
        }}
      />
    </BrowserRouter>
  );
};

export default App;
