import React from "react";
import { DecodeHTML } from "../DecodeHTML";
import { RichTextStyle } from "./style";

const RichText = props => {
  return (
    <RichTextStyle
      className={`richText`}
      dangerouslySetInnerHTML={{
        __html: DecodeHTML(props.content),
      }}
    />
  );
};

export default RichText;
