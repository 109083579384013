import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const CreativeVisaFormContainer = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(24)};
  ${desktopMedia} {
    padding: ${pxToRem(64)} ${pxToRem(88)};
    background: ${props => props.theme.palette.lightYellow};
  }
`;

export const CreativeVisaFormStyle = styled.form`
  ${desktopMedia} {
    background-color: ${props => props.theme.mixins.White()};
    padding: ${pxToRem(48)};
    width: 60%;
  }
`;
export const ErrorMessageAPIStyle = styled.form`
  margin-top: ${pxToRem(20)};
`;

export const SuccessMessageStyle = styled.div`
  display: flex;
  align-items: center;
  min-height: ${pxToRem(100)};
`;

export const FieldStyle = styled.div`
  ${desktopMedia} {
    display: flex;
    flex-wrap: wrap;
  }
  fieldset {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    justify-content: flex-end;
    ${desktopMedia} {
      width: calc(50% - 16px);
    }
  }
`;
