import React from "react";
import { LinkStyle } from "./style";

const Link = props => {
  const { linkValue } = props;

  return <LinkStyle onClick={props.onChange}>{linkValue}</LinkStyle>;
};

export default Link;
