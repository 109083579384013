import { mixins } from "./mixins";

const colorMixins = mixins();

export const borders = {
  divider: `1px solid ${colorMixins.Black(200)}`,
  fieldBorder: "1px solid rgb(86, 90, 92)",
  dropDownBorder: `1px solid ${colorMixins.Grey(400)}`,
  redBorder: "1px solid rgb(194, 0, 47)",
};
