import React from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";

import App from "./App";
import theme from "./styles/themes";

const Layout = props => {
  if (props.lang === "ar" && document.querySelector("body")) {
    document.querySelector("body").setAttribute("dir", "rtl");
  } else {
    if (document.querySelector("body")) {
      document.querySelector("body").setAttribute("dir", "ltr");
    }
  }
  console.log(theme(props.lang));
  return (
    <div>
      <ThemeProvider theme={theme(props.lang)}>
        <App lang={props.lang} />
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    lang: state.languageReducer.lang,
  };
};

export default connect(mapStateToProps, null)(Layout);
