import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

export const ButtonContainerStyle = styled.div`
  margin-top: ${pxToRem(16)};
`;

export const ButtonStyle = styled.button`
  padding: ${pxToRem(8)} ${pxToRem(24)};
  background-color: ${props => props.theme.palette.red};
  color: ${props => props.theme.mixins.White()};
  border: none;
  border-radius: 28px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  :disabled {
    background-color: ${props => props.theme.palette.disabledRed};
    cursor: no-drop;
  }
`;
