import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.palette.greyBlack};
	color: ${props => props.theme.mixins.White()};
  padding: ${pxToRem(24)} ${pxToRem(24)};
  ${desktopMedia} {
	justify-content: space-between;
	flex-direction: row;
    align-items: flex-start;
    padding: ${pxToRem(24)} ${pxToRem(88)};
  }
	h4 {
		font-size: ${pxToRem(22)};
	}
	a {
		color: ${props => props.theme.mixins.White()};
		text-decoration: none;
	}
	.links {
		margin-bottom: ${pxToRem(10)};
	}
`;

export const FooterBottomSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.darkGrey};
  color: ${props => props.theme.mixins.White()};
  padding: ${pxToRem(24)} ${pxToRem(24)};
  ${desktopMedia} {
    padding: ${pxToRem(24)} ${pxToRem(88)};
  }

	ul {
		margin: 0;
		list-style: none;
		padding: 0;
		display: flex;
		li {
			a {
				color: ${props => props.theme.mixins.White()};
				text-decoration: none;
			}
		}
		li:first-child {
			${props => props.theme.mixins.marginRight(12)};
		}
	}
`;

export const OfficesSection = styled.div`
	${desktopMedia} {
		width: 33%;
  	}
	img {
		width: 100%;
		max-width: 256px;
	}
`;

export const QuickLinkSection = styled.div`
	${desktopMedia} {
		width: 20%;
  	}
`;

export const ContactSection = styled.div`
	${desktopMedia} {
		width: 27%;
  	}
`;

export const FollowSection = styled.div`
	${desktopMedia} {
		width: 18%;
  	}
	ul {
		margin: 0;
		list-style: none;
		padding: 0;
		display: flex;
		li {
			a {
				color: ${props => props.theme.mixins.White()};
				text-decoration: none;
			}
			img {
				width: ${pxToRem(22)};
				${props => props.theme.mixins.marginRight(10)};
			}
		}
	}
	img {
		width: ${pxToRem(170)};
		margin-top: ${pxToRem(20)};
	}
`;
