import React from "react";
import { ErrorMessageStyle } from "./style";
import { getLanguageValues } from "../../../Utils/MultiLingualValues";

const ErrorMessage = props => {
  return (
    <ErrorMessageStyle>
      {props.message ? props.message : getLanguageValues(props.lang, "requiredFieldText")}
    </ErrorMessageStyle>
  );
};

export default ErrorMessage;
