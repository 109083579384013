import React from "react";
import { Controller } from "react-hook-form";

import SelectStyle from "./style";
import Select from "react-select";

const SelectBox = (props) => {
  if (props.isLinkedToForm) {
    const {
      fieldType,
      fieldLabel,
      fieldName,
      listItems,
      isRequired,
    } = props.data;

    if (listItems.length < 1) {
      return "";
    }

    return (
      <SelectStyle>
        <label
          htmlFor={`${fieldType}_${fieldName}`}
          className={props.errors && props.errors[fieldName] && "error"}
        >
          {fieldLabel && fieldLabel}
          {isRequired ? "*" : ""}
        </label>
        <Controller
          name={fieldName}
          control={props.formControl}
          rules={{ required: isRequired }}
          render={({ onChange }) => (
            <Select
              options={listItems}
              noOptionsMessage={() => ""}
              className={`select-box ${
                props.errors && props.errors[fieldName] ? "error" : ""
              }`}
              onChange={(e) => onChange(e.value)}
              // placeholder={fieldLabel}
            />
          )}
        />
        {/* {props.errors && props.errors[fieldName] && (
          <ErrorMessage lang={props.lang} />
        )} */}
      </SelectStyle>
    );
  } else {
    return (
      <div>
        <Select
          options={props.options}
          onChange={props.onChange}
          value={props.value}
        />
      </div>
    );
  }
};

export default SelectBox;
