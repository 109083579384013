import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

export const UploadedDOCStyle = styled.div`
  margin-top: ${pxToRem(8)};
  /* color: ${props => props.theme.palette.darkBlue}; */
`;

export const RemoveFileStyle = styled.span`
  ${props => props.theme.mixins.marginLeft(12)};
  color: ${props => props.theme.palette.red};
  cursor: pointer;
`;
export const UploadIconStyle = styled.div`
  min-width: 38px;
  height: 38px;
  position: relative;
  display: inline-block;
  background-color: ${props => props.theme.palette.red};
  border-radius: 20px;
  ${props => props.theme.mixins.marginRight(12)};
  ::after {
    content: "";
    position: absolute;
    border: solid white;
    top: 13px;
    ${props => props.theme.mixins.left(14)};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-135deg);
  }
  ::before {
    content: "";
    position: absolute;
    border: 1px solid white;
    top: 14px;
    ${props => props.theme.mixins.left(18)};
    display: inline-block;
    height: 10px;
  }
  &.disabled {
    background-color: ${props => props.theme.mixins.Black(500)};
  }
`;

export const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
