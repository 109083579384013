import React from "react";
import { CheckboxStyle, CheckboxOptionStyle } from "./style";
import ErrorMessage from "../../ErrorMessage";

const CheckBox = props => {
  const { fieldType, fieldLabel, fieldName, isRequired } = props.data;
  let extraProps = {};
  if(props.onChange) {
    extraProps = {
      onChange: props.onChange
    };
  }
  return (
    <CheckboxStyle>
      <CheckboxOptionStyle>
        <input
          id={`${fieldType}_${fieldName}`}
          type="checkbox"
          name={fieldName}
          ref={props.reference}
          {...extraProps}
        />
        <label htmlFor={`${fieldType}_${fieldName}`}>{fieldLabel}{isRequired ? '*' : ''}</label>
      </CheckboxOptionStyle>
      {props.errors && props.errors[fieldName] && (
        <ErrorMessage lang={props.lang} />
      )}
    </CheckboxStyle>
  );
};

export default CheckBox;
