import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

export const RadioStyle = styled.fieldset`
  width: 100% !important;
`;

export const RadioOptionStyle = styled.div`
  display: flex;
  margin-top: ${pxToRem(8)};
  span {
    display: flex;
    label {
      margin: 0;
    }
  }
`;

export const LabelStyle = styled.div`
  display: block;
  margin: 1rem 0 0.25rem;
  font-size: 18px;
`;
