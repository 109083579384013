import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import store from "./redux/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import Layout from "./Layout";
import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  process.env.REACT_APP_AUTH_TOKEN;

const GTA_ID = process.env.REACT_APP_GTA_KEY;

  const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', GTA_ID);
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Layout />
      {/* Global site tag (gtag.js) - Google Analytics */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GTA_ID}`}
      />
      <script>{injectGA()}</script>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
