import React from "react";
import Datepicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { DatePickerStyle } from "./style";

const DatePicker = (props) => {
  const { fieldType, fieldLabel, fieldName, showTodayDate, isRequired } =
    props.data;
  const { errors } = props;

  return (
    <DatePickerStyle>
      <label
        htmlFor={`${fieldType}_${fieldName}`}
        className={errors && errors[fieldName] && "error"}
      >
        {fieldLabel && fieldLabel}
        {isRequired ? "*" : ""}
      </label>
      <Controller
        control={props.formControl}
        name={fieldName}
        fieldType={fieldType}
        rules={{ required: true }}
        render={(props) => {
          return (
            <Datepicker
              className={`date-picker ${
                errors && errors[fieldName] ? "error" : ""
              }`}
              id={`${fieldType}_${fieldName}`}
              // placeholderText={fieldLabel && fieldLabel}
              utcOffset={0}
              onChange={(e) => props.onChange(e)}
              selected={props.value}
              name={props.name}
              autoComplete="off"
              showYearDropdown="true"
              scrollableYearDropdown="true"
            />
          );
        }}
      />
      {/* {props.errors && props.errors[fieldName] && (
        <ErrorMessage lang={props.lang} />
      )} */}
    </DatePickerStyle>
  );
};

export default DatePicker;
