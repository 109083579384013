import { getLanguageValues } from "../../../Utils/GoldenMultiLingualValues";

export const formData = (lang) => {
  const processedData = [
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "personalInformation"),
      groupDisclaimer: getLanguageValues(lang, "PersonalInformationDisclaimer"),
      fields: [
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "salutationId"),
          isRequired: true,
          listItems: [],
          fieldName: "salutationId",
          keyName: "salutations",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "firstName"),
          isRequired: true,
          listItems: [],
          fieldName: "firstName",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "middleName"),
          isRequired: false,
          listItems: [],
          fieldName: "middleName",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "surName"),
          isRequired: true,
          listItems: [],
          fieldName: "surName",
        },
        {
          fieldType: "email",
          fieldLabel: getLanguageValues(lang, "email"),
          isRequired: true,
          listItems: [],
          fieldName: "email",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "genderId"),
          isRequired: true,
          listItems: [],
          fieldName: "genderId",
          keyName: "genders",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "nationalityAndPassport"),
      fields: [
        {
          fieldType: "date",
          fieldLabel: getLanguageValues(lang, "dateOfBirth"),
          isRequired: true,
          listItems: [],
          fieldName: "dateOfBirth",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "birthCountryId"),
          isRequired: true,
          listItems: [],
          fieldName: "birthCountryId",
          keyName: "countries",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "birthCity"),
          isRequired: true,
          listItems: [],
          fieldName: "birthCity",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "previousNationalityId"),
          isRequired: false,
          listItems: [],
          fieldName: "previousNationalityId",
          keyName: "nationalites",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "nationalityId"),
          isRequired: true,
          listItems: [],
          fieldName: "nationalityId",
          keyName: "nationalites",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "passportTypeId"),
          isRequired: true,
          listItems: [],
          fieldName: "passportTypeId",
          keyName: "passportTypes",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "passportCodeText"),
          isRequired: true,
          listItems: [],
          fieldName: "passportCodeId",
          keyName: "passportCodes",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "passportIssueCountryId"),
          isRequired: true,
          listItems: [],
          fieldName: "passportIssueCountryId",
          keyName: "countries",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "passportNumber"),
          isRequired: true,
          listItems: [],
          fieldName: "passportNumber",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "passportIssueCity"),
          isRequired: true,
          listItems: [],
          fieldName: "passportIssueCity",
        },
        {
          fieldType: "date",
          fieldLabel: getLanguageValues(lang, "passportIssueDate"),
          isRequired: true,
          listItems: [],
          fieldName: "passportIssueDate",
        },
        {
          fieldType: "date",
          fieldLabel: getLanguageValues(lang, "passportExpiryDate"),
          isRequired: true,
          listItems: [],
          fieldName: "passportExpiryDate",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "occupationAndQualification"),
      fields: [
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "occupation"),
          isRequired: true,
          listItems: [],
          fieldName: "occupation",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "qualificationId"),
          isRequired: true,
          listItems: [],
          fieldName: "qualificationId",
          keyName: "qualifications",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "qualificationCountryId"),
          isRequired: false,
          listItems: [],
          fieldName: "qualificationCountryId",
          keyName: "countries",
          parentField: "qualificationId",
          parentValues: "3,4,5,6,7,8,10",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "educationLevelDetails"),
          isRequired: false,
          listItems: [],
          fieldName: "educationLevelDetails",
          parentField: "qualificationId",
          parentValues: "3,4,5,6,10",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "visaInformation"),
      fields: [
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "maritalStatusId"),
          isRequired: true,
          listItems: [],
          fieldName: "maritalStatusId",
          keyName: "maritalStatuses",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "motherName"),
          isRequired: true,
          listItems: [],
          fieldName: "motherName",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "religionId"),
          isRequired: true,
          listItems: [],
          fieldName: "religionId",
          keyName: "religions",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "faithId"),
          isRequired: true,
          listItems: [],
          fieldName: "faithId",
          keyName: "faiths",
          religionCondition: "8,9",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaeUnifiedNo"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeUnifiedNo",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaeIdentityNumber"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeIdentityNumber",
        },
        {
          fieldType: "radio",
          fieldLabel: getLanguageValues(lang, "hasUaeVisa"),
          isRequired: true,
          listItems: [
            {
              value: getLanguageValues(lang, "yes"),
              key: true,
            },
            {
              value: getLanguageValues(lang, "no"),
              key: false,
            },
          ],
          fieldName: "hasUaeVisa",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "sponsorName"),
          isRequired: true,
          listItems: [],
          fieldName: "sponsorName",
          uaeVisaCondition: "true",
        },
        {
          fieldType: "dropdown",
          isRequired: true,
          listItems: [],
          fieldLabel: getLanguageValues(lang, "visaType"),
          fieldName: "ExistingVisaTypeId", //
          keyName: "existingVisaTypes",
          uaeVisaCondition: "true",
        },

        {
          fieldType: "date",
          fieldLabel: getLanguageValues(lang, "visaExpiringDate"),
          isRequired: true,
          showTodayDate: true,
          fieldName: "existingVisaExpiryDate",
          uaeVisaCondition: "true",
        },
        {
          fieldType: "radio",
          fieldLabel: getLanguageValues(lang, "hasLegalIssue"),
          isRequired: true,
          listItems: [
            {
              value: getLanguageValues(lang, "yes"),
              key: true,
            },
            {
              value: getLanguageValues(lang, "no"),
              key: false,
            },
          ],
          fieldName: "hasLegalIssue",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "legalIssueDetails"),
          isRequired: true,
          listItems: [],
          fieldName: "legalIssueDetails",
          legalIssueCondition: "true",
        },
        {
          fieldType: "radio",
          fieldLabel: getLanguageValues(lang, "havedependantsSponsor"),
          isRequired: true,
          listItems: [
            {
              value: getLanguageValues(lang, "yes"),
              key: true,
            },
            {
              value: getLanguageValues(lang, "no"),
              key: false,
            },
          ],
          fieldName: "havedependantsSponsor",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "permanentContactInformation"),
      fields: [
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "homeCountryBuilding"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryBuilding",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "homeCountryStreet"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryStreet",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "homeCountryCity"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryCity",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "homeCountryState"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryState",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "homeCountryId"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryId",
          keyName: "countries",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "homeCountryZipCode"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryZipCode",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "homeCountryMobile"),
          isRequired: true,
          listItems: [],
          fieldName: "homeCountryMobile",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "homeCountryOtherContact"),
          isRequired: false,
          listItems: [],
          fieldName: "homeCountryOtherContact",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "contactInformation"),
      fields: [
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "uaeBuilding"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeBuilding",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "uaeStreet"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeStreet",
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "uaeCity"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeCity",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "uaeEmirateId"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeEmirateId",
          keyName: "emirates",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaePoBox"),
          isRequired: false,
          listItems: [],
          fieldName: "uaePoBox",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaeResidencePhoneNumber"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeResidencePhoneNumber",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaeOtherContact"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeOtherContact",
        },
        {
          fieldType: "number",
          fieldLabel: getLanguageValues(lang, "uaeMobile"),
          isRequired: false,
          listItems: [],
          fieldName: "uaeMobile",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "creativeVisaCategories"),
      fields: [
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "visaCategoryId"),
          isRequired: true,
          listItems: [],
          fieldName: "visaCategoryId",
          keyName: "visaCategories",
        },
        {
          fieldType: "dropdown",
          fieldLabel: getLanguageValues(lang, "visaActivityId"),
          isRequired: true,
          listItems: [],
          fieldName: "visaActivityId",
          keyName: "visaActivities",
          visaCategoryCondition: "visaCategoryId",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "supportingDocuments"),
      groupDisclaimer: getLanguageValues(lang, "supportingDocumentsDisclaimer"),

      fields: [
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Passport"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_Passport",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Colour_photo"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_Colour_photo",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Supporting_doc1"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_Supporting_doc1",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Supporting_doc2"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_Supporting_doc2",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Supporting_doc3"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_Supporting_doc3",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_EvidenceWorkAUH"),
          isRequired: true,
          listItems: [],
          fieldName: "attachment_EvidenceWorkAUH",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(
            lang,
            "attachment_Additional_Supporting_Docs"
          ),
          isRequired: false,
          listItems: [],
          fieldName: "attachment_Additional_Supporting_Docs",
          isMultipleUpload: true,
          maxUpload: 10,
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_Uae_Visa"),
          isRequired: false,
          listItems: [],
          fieldName: "attachment_Uae_Visa",
          uaeVisaCondition: "true",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(lang, "attachment_EmiratesId"),
          isRequired: false,
          listItems: [],
          fieldName: "attachment_EmiratesId",
          uaeVisaCondition: "true",
        },
        {
          fieldType: "file",
          fieldLabel: getLanguageValues(
            lang,
            "attachment_Copy_UAE_HEALTH_INSURANCE"
          ),
          isRequired: false,
          listItems: [],
          fieldName: "attachment_Copy_UAE_HEALTH_INSURANCE",
          uaeVisaCondition: "true",
          // HealthInsuranceCondition: "2",
        },
      ],
    },
    {
      isFormGroup: true,
      groupTitle: getLanguageValues(lang, "declarationText"),
      fields: [
        {
          fieldType: "readonly",
          content: getLanguageValues(lang, "declarationContentText"),
          richText: true,
        },
        {
          fieldType: "textbox",
          fieldLabel: getLanguageValues(lang, "name"),
          isRequired: true,
          listItems: [],
          fieldName: "name",
        },
        {
          fieldType: "date",
          fieldLabel: getLanguageValues(lang, "sign_date_form"),
          isRequired: true,
          listItems: [],
          fieldName: "sign_date_form",
          showTodayDate: true,
        },
        {
          fieldType: "checkbox",
          fieldLabel: getLanguageValues(lang, "signature"),
          isRequired: true,
          listItems: [],
          fieldName: "signature",
        },
        {
          fieldType: "checkbox",
          fieldLabel: getLanguageValues(lang, "terms"),
          isRequired: true,
          listItems: [],
          fieldName: "terms",
        },
      ],
    },
    {
      isFormGroup: false,
      fieldType: "captcha",
      captchaKey: process.env.REACT_APP_CAPTCHA_KEY,
    },
    {
      isFormGroup: false,
      fieldType: "submit",
      fieldLabel: getLanguageValues(lang, "submitText"),
    },
  ];
  return processedData;
};
